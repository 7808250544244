<template>
  <div class="product-detail-page">
    <div class="sidebar left"></div>
    <div v-if="loading_product_detail" class="spinner-container">
      <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
    </div>
    <product-not-found v-else-if="!product_detail" />
    <product-detail-item v-else :data="product_detail" />
    <div class="sidebar right"></div>
  </div>
</template>
<script>
export default {
  name: 'dandelionStoreProductDetail',
  components: {
    productNotFound: () => import('./dandelion-product-not-found.vue'),
    productDetailItem: () => import('./dandelion-product-detail-item.vue'),
  },
  props: ['store_alias'],
  computed: {
    product_detail() {
      const output = this.$store.state.dandelionStore.product_detail;
      if (!output || !output.combinations) {
        return null;
      }
      const scanned_object = output.combinations.find((x) => x.ean_no === this.product_barcode);
      if (Object.keys(scanned_object).length > 5) {
        return output;
      }
      return null;
    },
    loading_product_detail() {
      return this.$store.state.dandelionStore.loading_product_detail;
    },
    product_barcode() {
      return this.$route.params.id ? this.$route.params.id : '';
    },
  },
  watch: {
    '$route.path': {
      handler(val) {
        this.fetchProductDetail();
      },
    },
  },
  mounted() {
    this.$store.dispatch('getDandelionStoreBanner', 'store_admin_price_checker_pdp');
    this.fetchProductDetail();
  },
  methods: {
    goToProduct(code) {
      if (code === this.$route.params.id) {
        return;
      }
      this.$router.push(`/dandelion-store/${this.store_alias}/product/${code}`).catch((err) => console.log(err));
    },
    fetchProductDetail() {
      this.$store.dispatch('getProductDetail', {
        barcode: this.product_barcode,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product-detail-page {
  background: rgba(255, 205, 225, 1);
  width: 100vw;
  height: 100vh;
  @media (max-width: 768px) {
    .sidebar {
      display: none;
    }
    background: #ffffff;
  }
  .sidebar {
    position: fixed;
    width: 90px;
    top: 0;
    bottom: 0;
    background-repeat: repeat-y;
    background-image: url('/static/img/dandelion-store/vertical-border.png');
    background-size: cover;
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
  }

  .spinner-container .loaderspin {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
